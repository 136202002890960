import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['ButtonDialog_try'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextStepper = makeShortcode("TextStepper");
const ButtonDialog = makeShortcode("ButtonDialog");
const SingleImage = makeShortcode("SingleImage");
const GoTo = makeShortcode("GoTo");
const TextCustom = makeShortcode("TextCustom");
const BasicAudio = makeShortcode("BasicAudio");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "stress-management-skills"
    }}>{`Stress management skills`}</h1>
    <p>{`Problems and obstacles in life can sometimes feel overwhelming and stressful. Here are some stress management strategies you can share with your teenager and use to manage your own stress too. By using these strategies yourself, you show your teenager how to cope well with stressful situations.`}</p>
    <TextStepper id="textStepperStressManagement" sequenceSpeed={0} fadeSpeed={0} list={[{
      long: true,
      text: <p>
          <b>Focus on what you can control</b> (your actions and reactions) <b>and let go of what you cannot control</b>{' '}
          (other people’s opinions and actions).
        </p>
    }, {
      long: true,
      text: <p>
          <b>Break tasks down into small steps</b> so they are less overwhelming.
        </p>
    }, {
      long: true,
      text: <p>
          <b>Don't try to be perfect and responsible for everything.</b> If you need help with something, just ask for
          it!
        </p>
    }, {
      long: true,
      text: <p>
          <b>Avoid overscheduling.</b> Consider cutting out an activity or two, keeping only the most important ones.
        </p>
    }, {
      long: true,
      text: <p>
          <b>Talk</b> with someone you trust about what you're going through and how it's affecting you.
        </p>
    }, {
      long: true,
      text: <div>
          <p>
            <b>Use relaxation techniques.</b> Learn simple breathing exercises to help you relax. Try using them when
            you’re caught up in stressful situations.
          </p>
          <ButtonDialog linkText="Click to try" id="try" mdxType="ButtonDialog">
            <h3>Deep Breathing for Relaxation</h3>
            <ul>
              <li> Sit, stand or lie somewhere comfortable.</li>
              <li>
                Breathe in steadily, through your nose for a count of five in your head - let your abdomen expand fully.
              </li>
              <li>
                Hold it for two, then exhale through your mouth for five - again steadily and in control. Feel your
                belly rise each time you inhale and fall as you exhale.
              </li>
              <li>
                Repeat this for 1 to 2 minutes or until you feel yourself relaxing. Pay attention to how you feel when
                you breathe deeply.
              </li>
            </ul>
            <p>If you start to feel light-headed, please return to normal breathing.</p>
            <SingleImage src="/images/m8/57.svg" alt="Breathing Exercise" mdxType="SingleImage" />
          </ButtonDialog>
        </div>
    }, {
      long: true,
      text: <p>
          <b>Sleep well.</b> Getting enough sleep is essential when dealing with stress. See the module{' '}
          <GoTo to="/dashboard" mdxType="GoTo">
            <i>Good health habits for good mental health</i>
          </GoTo>{' '}
          for tips on improving sleep.
        </p>
    }, {
      long: true,
      text: <p>
          <b>Exercise regularly.</b> Regular exercise is a great way to manage stress. See the module{' '}
          <GoTo to="/dashboard" mdxType="GoTo">
            <i>Good health habits for good mental health</i>
          </GoTo>{' '}
          for more information.
        </p>
    }, {
      long: true,
      text: <p>
          <b>Eat well.</b> A nutritious, balanced diet is important to keep you physically and mentally well. See the
          module{' '}
          <GoTo to="/dashboard" mdxType="GoTo">
            <i>Good health habits for good mental health</i>
          </GoTo>{' '}
          for some tips on healthy eating.
        </p>
    }, {
      long: true,
      text: <p>
          <b>Enjoy relaxing activities.</b> Read a book, take a bath, spend time with your pet, listen to music, speak
          to a friend, go for a walk, or make time for a hobby.
        </p>
    }]} mdxType="TextStepper" />
    <TextCustom variant="subtitle2" paragraph mdxType="TextCustom">
  Source: Center for Adolescent Health. Johns Hopkins Bloomberg School of Public Health
    </TextCustom>
    <TextCustom customColor="blue" mdxType="TextCustom">
  <i>
    How do you know that your parents are stressed? When you get stressed out, what are a few things Mum or Dad could do
    to help?
  </i>{' '}
  Hear what Tam, 14 years-old, says…
    </TextCustom>
    <BasicAudio src="/audios/module_8_audio_clip.mp3" mdxType="BasicAudio" />
    <ButtonDialog id="parentStress" linkText="Read Transcript" title="How do you know that your parents are stressed? When you get stressed out, what are a few things Mum or Dad could do to help?" mdxType="ButtonDialog">
  <p>Tam, 14 years, says…</p>
  <p>
    I know when my parents are stressed out because they look tired, they snap at me, they can’t be bothered listening
    to me.
  </p>
  <p>
    They start yelling and blaming me for things that I didn’t do. Then they don’t want to listen to me and they start
    to make a big deal out of little things.
  </p>
  <p>
    When my parents are stressed, they don’t care if I’m in the middle of doing something, I have to stop what I’m doing
    and do what they want <i>straight away</i>. If I don't do what they want, when they want, they get <i>really</i>{' '}
    impatient.
  </p>
  <p>
    When I get stressed out, my parents could help by <i>actually</i> leaving me alone when I ask them to{' '}
    <i>leave me alone</i>.
  </p>
  <p>
    I’d like it if they gave me some space, and <i>then</i> knock on my door.
  </p>
  <p>
    <i>Don’t</i> come knocking on my door straight away.
  </p>
  <p>
    They could always try listening to me without interrupting and <i>not</i> ask a million questions. Just let me tell
    you what’s going on and <i>don’t</i> force me to speak.
  </p>
    </ButtonDialog>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      